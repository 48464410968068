import React from 'react'
import Hero from '../Components/Hero';

export default function Home() {
  return (
    <div id="content">
      <Hero />
    </div>
  )
}
