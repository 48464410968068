export default function Contact() {
  return (
    <about>
        <h1>HK-Tekniikka</h1>
        <h2>Henri Koski</h2>
        <h2>Puh: 040 502 2669</h2>
        <h2>Mail: hk-tekniikka@dnainternet.net</h2>
    </about>
  )
}
